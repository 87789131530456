<template>
  <div v-if="line" class="line">
    <div class="line-description">
      <div style="flex: 1; padding: 3px 6px">
        <p><b>Title: </b> {{ line.title }}</p>
        <p v-if="line.description">
          <b>Description: </b> {{ line.description }}
        </p>
        <p
          v-if="line.proposal && line.proposal.user && line.proposal.user.name"
        >
          <b>Proposal made by: </b> {{ line.proposal.user.name }}
        </p>
      </div>
      <div v-if="isProposalSent && !isAccepted" class="line-description-action">
        <BaseButton
          :text="isEditing ? 'Cancel' : 'Edit values'"
          class-name="info"
          @click="
            isEditing = !isEditing;
            isProposalSent = false;
          "
        />
      </div>
    </div>
    <div class="line-body">
      <div class="form">
        <div class="form-item info">
          <h4 class="info">Cases</h4>
          <p class="form-item-count green">{{ line.cases_count }}</p>
          <!-- <p class="form-item-count"></p> -->
          <p class="form-item-text">(Up to)</p>
        </div>

        <!-- <div class="form-item">
          <h4>Available</h4>
          <input
            v-model="userValues.available"
            type="number"
            class="line-quantity-input"
            placeholder="Ex: 5"
            :disabled="isProposalSent"
          />
          <p class="form-item-text">Cases</p>
        </div> -->

        <div class="form-item">
          <h4>To collect</h4>
          <input
            v-model="userValues.to_collect"
            type="number"
            class="line-quantity-input"
            placeholder="Ex: 3"
            :disabled="isProposalSent"
          />
          <p class="form-item-text">Cases</p>
        </div>

        <div class="form-item">
          <h4>Accrual Rate</h4>
          <input
            v-model="userValues.timeframe"
            type="text"
            class="line-quantity-input"
            :disabled="!userValues.to_collect || isProposalSent"
          />
          <!-- <Datepicker
                v-model="userValues.timeframe"
                format="YYYY-MM-DD"
                :lowerLimit="today"
                :disabled="!userValues.to_collect || isProposalSent"
              /> -->
        </div>

        <div v-if="user.role == 'admin'" class="form-item">
          <h4>Price</h4>
          <input
            v-model="userValues.price"
            type="number"
            class="line-quantity-input"
            placeholder="0"
            :disabled="isProposalSent"
          />
          <p class="form-item-text">$ Per case</p>
        </div>

        <div v-else class="form-item">
          <h4>Price</h4>
          <p class="form-item-count green">n/a</p>
          <!-- <p class="red center">is available</p> -->
          <!-- <p class="red center">only for admin</p> -->
        </div>

        <div v-if="isAccepted" class="form-item">
          <h4>Ordered</h4>
          <p v-if="userValues.ordered" style="margin: 12px 0 4px">
            {{ userValues.ordered }}
          </p>
          <p v-else-if="userValues.to_collect" style="margin: 10px 0">
            {{ userValues.to_collect }}
          </p>
          <p v-else style="margin: 10px 0">0</p>
          <p class="form-item-text">Cases</p>
        </div>

        <div class="form-item">
          <h4>Status</h4>
          <div
            v-if="isAccepted"
            class="line-status-checkbox-wrapper"
            :class="userValues.status"
          >
            <div class="custom-checkbox ticked"></div>
            <span>Accepted</span>
          </div>
          <p
            v-else-if="isProposalSent"
            class="line-status-checkbox-wrapper"
            :class="userValues.status"
          >
            <span>{{ proposalStatusText }}</span>
            <span class="dots">. . .</span>
          </p>
          <p v-else class="dash info">-</p>
        </div>
      </div>
    </div>

    <div>
      <div v-if="isProposalSent" class="line-comment">
        <p v-if="line.proposal.comment" class="green">
          <b>Your comments / Accrual timeline:</b>
          {{ line.proposal.comment }}
        </p>
      </div>

      <div v-else class="line-comment">
        <h4 class="green">Your comments / Accrual timeline:</h4>
        <textarea rows="2" class="line-textarea" v-model="userValues.comment">
        </textarea>
      </div>

      <div v-if="!isProposalSent" class="line-button-wrapper">
        <BaseButton
          v-if="isEditing"
          text="Cancel"
          class-name="warning"
          :disabled="isProposalSent"
          @click="
            isProposalSent = true;
            isEditing = !isEditing;
          "
        ></BaseButton>

        <BaseButton
          text="Save"
          class-name="info"
          :disabled="isProposalSent"
          @click="submitUserValues(line.id)"
        ></BaseButton>
      </div>
    </div>

    <div
      v-if="line.proposal && line.proposal.status === 'accepted'"
      class="line-tracking-wrapper"
    >
      <div
        v-for="(track, index) in trackingList"
        class="line-tracking"
        :key="index"
      >
        <div class="line-tracking-item line-tracking-item_number">
          <h4 v-if="!track.id" class="green">Tracking #</h4>
          <input
            type="text"
            v-model="track.tracking"
            class="line-track-input"
            :disabled="track.id || track.disabled"
          />
        </div>
        <div class="line-tracking-item">
          <h4 v-if="!track.id" class="green">Cases #</h4>
          <!-- <p v-if="track.id || track.disabled">{{ track.cases_count }}</p> -->
          <input
            type="number"
            v-model="track.cases_count"
            class="line-quantity-input"
            :disabled="track.id || track.disabled"
          />
        </div>
        <div class="line-tracking-item">
          <h4 v-if="!track.id" class="green">Date Shipping</h4>
          <p v-if="track.id">
            {{ track.date_shipping }}
          </p>
          <Datepicker
            v-else
            v-model="track.date_shipping"
            format="Y-M-D"
            :disabled="track.id || track.disabled"
          />
        </div>
        <div class="line-tracking-item">
          <BaseButton
            v-if="!track.id"
            class-name="info"
            text="Save"
            :disabled="track.disabled"
            @click="saveShipment(track)"
          ></BaseButton>
        </div>
      </div>

      <button
        v-if="line.proposal.ordered >= shipmentCasesReducer"
        class="line-add-button"
        @click="createNewShipment"
      >
        + Add a new shipment
      </button>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Datepicker from "vue3-datepicker";
import convertDateFormat from "@/helpers/convertDateFormat.js";
import BaseButton from "@/modules/components/base/BaseButton.vue";

export default {
  components: { Datepicker, BaseButton },
  props: {
    line: {
      type: Object,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();

    let isAccepted = ref(false);
    let isEditing = ref(false);
    let availableCollect = ref(false);
    let today = new Date();
    let isProposalSent = ref(false);
    let proposalStatus = ref("");
    let proposalStatusText = ref("");

    let userValues = ref({
      available: null,
      to_collect: null,
      timeframe: null,
      price: null,
      comment: null,
    });
    let trackingList = ref([]);
    let user = computed(() => store.getters["auth/user"]);

    onMounted(() => {
      if (props.line.proposal) {
        const proposal = props.line.proposal;
        isProposalSent.value = !!proposal;
        proposalStatus.value = proposal.status;
        proposalStatusText.value =
          proposalStatus.value[0].toUpperCase() +
          proposalStatus.value.substring(1);

        userValues.value = JSON.parse(JSON.stringify(proposal));

        if (userValues.value.status === "accepted") {
          isAccepted.value = true;
        }

        if (proposal.timeframe) {
          userValues.value.timeframe = proposal.timeframe;
        }

        if (proposal.shipments) {
          trackingList.value = proposal.shipments;
        }

        if (isAccepted.value) {
          if (
            userValues.value.proposal?.proposal_shipment &&
            userValues.value.proposal?.proposal_shipment.lenght
          ) {
            trackingList.value = JSON.parse(
              JSON.stringify(userValues.value.proposal.proposal_shipment)
            );
            // } else {
            //   createNewShipment();
          }
          // } else {
          //   createNewShipment();
        }
      }
    });

    const submitUserValues = async () => {
      calculateAllowance();

      if (!availableCollect.value) {
        console.log("Please fill 'To collect' field");
        return;
      }

      if (user.value.role == "admin" && !userValues.value.price) {
        console.log("There is no price!");
        return;
      }
      if (isProposalSent.value) {
        console.log("Proposal is already sent!");
        return;
      }

      let preparedData = {
        // available: userValues.value.available,
        // price: userValues.value.price || 0,
        project_line_id: props.line.id,
      };

      if (user.value.role == "admin") {
        preparedData.price = userValues.value.price || 0;
        preparedData.status = "pending";
      }

      if (userValues.value.available) {
        preparedData.available = userValues.value.available;
      }

      if (userValues.value.to_collect) {
        preparedData.to_collect = userValues.value.to_collect;
      }

      if (userValues.value.comment) {
        preparedData.comment = userValues.value.comment;
      }

      if (userValues.value.to_collect && userValues.value.timeframe) {
        preparedData.timeframe = userValues.value.timeframe;
      }

      if (isEditing.value) {
        await store.dispatch("projects/editProjectLineProposal", {
          order: preparedData,
          proposalId: props.line.proposal.id,
        });
      } else {
        await store.dispatch("projects/setProjectLineProposal", {
          order: preparedData,
          projectId: props.projectId,
        });
      }

      await store.dispatch("projects/getProject", route.params.id);
    };

    const calculateAllowance = () => {
      availableCollect.value = userValues.value.to_collect;
    };

    const shipmentCasesReducer = computed(() => {
      return trackingList.value.reduce(
        (total, num) => total + num.cases_count,
        0
      );
    });

    const createNewShipment = () => {
      trackingList.value.push({
        tracking: null,
        cases_count: null,
        date_shipping: today,
        project_line_proposal_id: null,
        disabled: false,
      });
    };

    const saveShipment = (track) => {
      track.disabled = true;
      if (!track) return;

      if (!track.tracking) {
        console.log("Enter tracking number");
        return;
      }

      if (!track.cases_count) {
        console.log("Enter cases count");
        return;
      }

      if (!track.date_shipping) {
        console.log("Enter date shipping");
        return;
      }

      let preparedData = {
        // delivery_service: null,
        tracking: track.tracking,
        cases_count: track.cases_count,
        project_line_proposal_id: props.line.proposal.id,
      };

      if (track.date_shipping) {
        preparedData.date_shipping = convertDateFormat.getYyyyMmDd(
          track.date_shipping
        );
      }

      store.dispatch("projects/setTrackingShipment", preparedData);
    };

    const editProposal = (p) => {
      isEditing.value = false;

      if (p.available) {
        userValues.value.available = p.available;
      }
      if (p.to_collect) {
        userValues.value.to_collect = p.to_collect;
      }
      if (p.timeframe) {
        userValues.value.timeframe = p.timeframe;
      }
      if (p.price) {
        userValues.value.price = p.price;
      }
    };

    return {
      userValues,
      isAccepted,
      isEditing,
      today,
      trackingList,
      isProposalSent,
      proposalStatus,
      proposalStatusText,
      shipmentCasesReducer,
      availableCollect,
      user,
      saveShipment,
      submitUserValues,
      createNewShipment,
      editProposal,
    };
  },
};
</script>

<style lang="scss" scoped>
.line {
  position: relative;
  margin: 10px 0;
  border: 1px solid var(--col-info);
  border-radius: 8px;

  &-description {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border: 1px solid var(--col-info);
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 14px;
    line-height: 1.4;
    color: var(--col-bg-secondary);
    background-color: var(--col-info);

    &-action {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      min-height: 100%;
      padding: 5px;
      border-top-right-radius: 5px;
      background-color: var(--col-contrast-text);
    }
  }

  &-body {
    margin: 10px 0;
  }

  &-quantity-input,
  &-textarea,
  &-track-input {
    border: 1px solid var(--col-border);
    border-radius: 4px;
    color: var(--col-subtext-secondary);
  }

  &-textarea {
    width: 100%;
    padding: 5px;
  }

  &-quantity-input {
    width: 90%;
    margin: 10px 0 2px;
    text-align: center;
  }

  &-button-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &-status {
    &-checkbox {
      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        font-weight: 900;
        color: var(--col-info);

        &:not(.accepted) {
          .empty {
            margin-top: 10px;
            border-color: var(--col-warning);
          }

          & > span {
            color: var(--col-warning);
          }

          .dots {
            font-size: 22px;
            line-height: 1;
          }
        }

        .ticked {
          margin-bottom: 2px;
          border-color: var(--col-info);

          &::before,
          &::after {
            background-color: var(--col-info);
          }
        }
      }
    }
  }

  &-tracking {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 5px 0;

    &-wrapper {
      padding: 3px 6px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-basis: 22%;
      min-height: 100%;

      &_number {
        flex-basis: 34%;
      }

      button {
        margin: 10px 0 2px;
        padding-left: 25px;
        padding-right: 25px;
      }

      > * {
        min-width: 90%;
        margin: 10px auto 2px;
        padding: 0 7px;
        text-align: center;
      }

      > button {
        width: 100%;
      }
    }
  }

  &-track-input {
    margin: 10px 0 2px;
  }
}

.line-add-button {
  display: block;
  width: 100%;
  padding: 4px;
  border: 1px solid var(--col-primary-light);
  border-radius: 4px;
  text-align: center;
  color: var(--col-primary-dark);
  background-color: var(--col-info-bg);
}
.line-add-button:hover {
  border-style: solid;
}
.line-add-button:active,
.line-add-button:focus {
  border-color: var(--col-info);
}

.form {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 20%;
    min-height: 100%;
    border-left: 1px dotted var(--col-hr);

    &:first-of-type {
      border-left: none;
    }

    h4 {
      width: 100%;
      margin: 1px;
      text-align: center;
      color: var(--col-info);
      background-color: var(--col-info-bg);
      font-family: "SFProDisplay-Medium";
    }

    &-text {
      font-size: 12px;
      font-family: "OpenSans-Regular";
      color: var(--col-subtext-secondary);
    }

    &-count {
      margin: 10px 0 2px;
      font-size: 16px;
      font-weight: 900;
    }

    .dash {
      margin: 10px 0;
      font-weight: 900;

      &.info {
        color: var(--col-info);
      }
    }
  }
  // //  - to css end
}
</style>

<style lang="scss">
.line {
  &-comment {
    padding: 3px 6px;
    .textarea {
      background-color: var(--col-bg-secondary);
    }
  }
}
</style>
